<template>
  <v-sheet class="cms-content-card--wrap overflow-hidden" :color="fluidBackgroundColor">
    <v-container
      :fluid="fluid || fluidWithContainedContent"
      :class="{
        'py-0': !fluidWithContainedContent,
        'px-0': fluid || fluidWithContainedContent,
        'mb-15': addVerticalSpace,
      }"
    >
      <v-container
        :fluid="!fluidWithContainedContent"
        class="pa-0"
      >
        <v-card
          :color="color"
          flat
          rounded="0"
        >
          <v-row no-gutters>
            <v-col
              v-if="useGrid"
              :cols="12"
              :lg="useGrid ? mediaColWidth : 12"
              class="d-flex justify-center flex-column position-relative"
              :class="{
                'order-3': mediaMobileBelow,
                'order-lg-1': !mediaRight,
                'order-lg-2': mediaRight,
              }"
            >
              <cms-image-slider
                v-if="slider"
                v-bind="slider"
              />
              <video
                v-if="video"
                :alt="media.alternativeText || headline"
                controls
              >
                <source :src="video">
              </video>
              <cms-image
                v-if="media && !slider && !video"
                :image-data="media"
                :cover="mediaCover"
                :alt-fallback="headline"
                :img-classes="{
                  'height-by-content': mediaHeightByContent
                }"
              />
            </v-col>
            <v-col
              :cols="12"
              :lg="useGrid ? 12-mediaColWidth : 12"
              :class="mediaRight ? 'order-lg-1' : 'order-lg-2'"
              class="order-2 d-flex flex-column justify-center"
            >
              <div
                :class="{
                  'pa-6': dense,
                  'pa-8 pa-sm-15': !dense
                }"
              >
                <cms-breadcrumb
                  v-if="breadcrumb"
                  class="pa-0"
                  :class="breadcrumb?.dense ? 'mb-4' : 'mb-10'"
                />
                <div
                  v-if="overline && headline"
                  class="text-overline"
                  :class="{ 'text-primary': color === 'primary-tonal' }"
                >
                  {{ overline }}
                </div>
                <div
                  v-if="headline"
                  class="mb-5 font-weight-medium"
                  :class="{
                    'text-primary': color === 'primary-tonal',
                    'small-headline': isSmallHeadline,
                    'text-h4': !isSmallHeadline,
                  }"
                >
                  {{ headline }}
                </div>

                <div
                  v-if="text"
                  class="text-h6 font-weight-regular"
                >
                  <cms-html-content :html="text" />
                </div>

                <v-btn
                  v-if="actionLabel && actionLink"
                  :href="actionLink"
                  :color="actionColor"
                  flat
                  size="large"
                  :class="{
                    'text-primary': actionColor !== 'primary',
                    'mt-5': dense,
                    'mt-10': !dense,
                  }"
                >
                  {{ actionLabel }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'cms-content-card',

  props: {
    // adds a bottom-margin
    addVerticalSpace: {
      type: Boolean,
      default: true,
    },
    // reduced padding for the text-content-area
    dense: {
      type: Boolean,
      default: false,
    },
    // breadcrumb-cms-object. Gets shown above the headline-area
    breadcrumb: {
      type: Object,
      default: null,
    },
    // uses the full width
    fluid: {
      type: Boolean,
      default: false,
    },
    // fluid wrap, but contained content
    fluidWithContainedContent: {
      type: Boolean,
      default: false,
    },
    // bg-color to use for the fluid wrap (see fluidWithContainedContent-prop)
    fluidBackgroundColor: {
      type: String,
      default: 'transparent',
    },
    // strapi media-object
    media: {
      type: Object,
      default: null,
    },
    // media right, content left
    mediaRight: {
      type: Boolean,
      default: false,
    },
    // fixed height for the media
    mediaHeight: {
      type: Number,
      default: undefined,
    },
    // media uses the eight of the content-column (only before column-break)
    mediaHeightByContent: {
      type: Boolean,
      default: false,
    },
    mediaColWidth: {
      type: Number,
      default: 6,
    },
    // shows the media below the text-content on small displays
    mediaMobileBelow: {
      type: Boolean,
      default: false,
    },
    // let the media fill the available width, height
    mediaCover: {
      type: Boolean,
      default: true,
    },
    // background of the card
    color: {
      type: String,
      default: 'grey-lighten-4',
    },
    // text above the headline
    overline: {
      type: String,
      default: null,
    },
    headline: {
      type: String,
      default: null,
    },
    // headline with reduced font-size
    isSmallHeadline: {
      type: Boolean,
      default: false,
    },
    // uses the 'image-slider'-component instead of an image or a video.
    slider: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    // color of the action-button
    actionColor: {
      type: String,
      default: null,
    },
    // label of the button
    actionLabel: {
      type: String,
      default: null,
    },
    // target of the button
    actionLink: {
      type: String,
      default: null,
    },
  },

  computed: {
    useGrid () {
      return this.media || this.slider
    },

    video () {
      if (!this.media || !this.media.mime.includes('video')) {
        return null
      }

      return this.media.absoluteUrl
    },
  },
}
</script>

<style lang="scss">
  .cms-content-card--wrap {
    @media (min-width: 1280px) {
      .height-by-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .small-headline {
      font-size: 1.75rem;
    }
  }
</style>
