<template>
  <div class="image-card--wrap flex-grow-1 d-flex flex-column">
    <v-hover>
      <template #default="{ isHovering, props }">
        <v-card
          v-bind="props"
          :elevation="isHovering && !plain ? 4 : 0"
          class="is--effect-card flex-grow-1 d-flex flex-column"
          :class="{
            'text-center': centeredText,
          }"
          flat
          rounded="lg"
          :color="color"
          :to="link && !linkExtern ? link : undefined"
          :href="link && linkExtern ? link : undefined"
          :target="linkExtern ? '_blank' : undefined"
          :tabindex="0"
          v-on="overlay
            ? { click: () => showOverlay = true }
            : {}"
          @keydown.enter="showOverlay = true"
        >
          <NuxtPicture
            v-if="image"
            :src="image?.absoluteUrl"
            :cover="cover"
            :height="imageHeight"
            loading="lazy"
            fit="outside"
            format="webp"
            densities="x1 x2"
            placeholder
            class="rounded-lg flex-grow-0"
            :class="{ cover }"
            :alt="image?.alternativeText || title"
          />

          <v-sheet
            v-if="title"
            color="transparent"
            class="py-4 d-flex flex-column justify-center"
          >
            <v-card-title class="py-0">
              <span class="animated-gradient-text">
                {{ title }}
              </span>
            </v-card-title>

            <v-card-subtitle v-if="subtitle" class="text-subtitle-1">
              {{ subtitle }}
            </v-card-subtitle>
          </v-sheet>

          <v-card-text v-if="text" class="pt-0">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="text" />
          </v-card-text>

          <v-expand-transition v-if="overlay">
            <v-card
              v-if="showOverlay"
              variant="outlined"
              rounded="lg"
              color="primary"
              class="reveal-card d-flex flex-column text-left"
            >
              <v-card-title
                v-if="overlay.title"
                :title="overlay.title"
                class="text-h6 mb-4 bg-primary py-3"
              >
                {{ overlay.title }}
              </v-card-title>

              <v-card-text class="pb-0">
                <cms-html-content v-if="overlay.text" :html="overlay.text" />
              </v-card-text>

              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                  color="primary"
                  text="Schließen"
                  variant="elevated"
                  flat
                  @click.stop="showOverlay = false"
                />
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'image-card',

  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    // centers all texts of the card
    centeredText: {
      type: Boolean,
      default: false,
    },
    // clickable card
    link: {
      type: String,
      default: null,
    },
    linkExtern: {
      type: Boolean,
      default: false,
    },
    // custom color for the card
    color: {
      type: String,
      default: 'white'
    },
    image: {
      type: Object,
      default: null,
    },
    // custom height for the image
    imageHeight: {
      type: [String, Number],
      default: '240px',
    },
    // image fills available space instead of showing the whole image-content
    cover: {
      type: Boolean,
      default: true,
    },
    // no shadow on hover
    plain: {
      type: Boolean,
      default: false,
    },
    // an overlay gets shown when the card gets clicked
    overlay: {
      type: Object, // { title, html }
      default: null,
    }
  },

  data () {
    return {
      showOverlay: false,
    }
  },
}
</script>

<style lang="scss">
  .image-card--wrap {
    picture {
      display: inline-flex;
      overflow: hidden;

      img {
        margin: auto;
        max-width: 100%;
        object-fit: contain;
      }

      &.cover {
        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .v-card {
      outline-color: rgb(var(--v-theme-primary));
    }

    .v-card-text {
      font-size: 1rem;
    }

    .reveal-card {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      background: rgba(var(--v-theme-surface), 0.98);
    }
  }
</style>
