<template>
  <div class="card-grid--wrap">
    <v-row
      v-if="cards.length > 0"
      class="card-grid--wrap"
      :justify="align"
    >
      <v-col
        v-for="(card, i) in cards"
        :key="i"
        :cols="card.size || 12"
        :sm="card.sizeSm"
        :md="card.sizeMd"
        :lg="card.sizeLg"
        :xl="card.sizeXl"
        class="d-flex flex-column"
      >
        <cms-image-card
          v-bind="card"
          :class="{
            'cursor-pointer': isGallery
          }"
          @click="onImageClick(i)"
          @keydown.enter="onImageClick(i)"
        />
      </v-col>
    </v-row>

    <v-overlay
      v-if="isGallery"
      ref="galleryWrap"
      v-model="showGallery"
      eager
      class="gallery--wrap align-center justify-center"
    >
      <v-sheet class="swiper--wrap">
        <swiper-container :init="false">
          <swiper-slide v-for="(card, i) in cards" :key="i">
            <cms-image
              :image-data="card.image"
              :alt-fallback="card.title"
              width="100%"
              height="100%"
              cover
            />
            <v-sheet color="primary" class="pa-2 pa-sm-4 slide-caption text-h6">
              {{ card.title || card.image?.alternativeText }}
            </v-sheet>
          </swiper-slide>
        </swiper-container>
      </v-sheet>
    </v-overlay>

    <!--
    ---- Only used to help nuxt(img) detect the fullsize varians of the gallery
    ---- images since those are in an optional modal-dialog.
    ---- @see https://github.com/nuxt/image/issues/446
    --->
    <div v-if="isGallery" class="d-none">
      <cms-image
        v-for="(card, i) in cards"
        :key="`gallery_loader_${i}`"
        :image-data="card.image"
        :alt-fallback="card.title"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cms-card-grid',

  props: {
    cards: {
      type: Array,
      default: () => ([]),
    },

    isGallery: {
      type: Boolean,
      default: false,
    },

    align: {
      type: String,
      default: 'center',
      validator (value) {
        return ['start', 'center', 'end'].includes(value)
      },
    },
  },

  data () {
    return {
      showGallery: false,
      swiperElement: null,
      currentGalleryItem: 0,
    }
  },

  methods: {
    /**
     * onImageClick
     *
     * @param {number} index
     * @returns {undefined}
     */
    onImageClick (index) {
      if (!this.isGallery) {
        return
      }

      if (!this.swiperElement) {
        this.initGallery()
      }

      this.showGallery = true
      this.swiperElement.swiper.slideToLoop(index, 0)
    },

    /**
     * initGallery
     *
     * @returns {undefined}
     */
    initGallery () {
      const options = {
        slidesPerView: 1,
        effect: 'creative',
        navigation: true,
        pagination: true,
        autoHeight: true,
        observer: true,
        observeParents: true,
        creativeEffect: {
          prev: {
            shadow: false,
            translate: ['-20%', 0, -1],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        },
        injectStyles: [
          `
          .swiper-button-next, .swiper-button-prev {
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(3px);
            padding: 8px;
            transition-duration: 0.4s;
            transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
          }
          .swiper-button-next:hover, .swiper-button-prev:hover {
            transform: scale(1.14);
            border-radius: 8px;
            box-shadow:
              rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
              rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
              rgba(0, 0, 0, 0.12) 0px 1px 18px 0px
            ;
          }
          `,
        ],
      }

      this.swiperElement = this.$refs.galleryWrap.contentEl.querySelector('swiper-container')

      if (this.swiperElement) {
        Object.assign(this.swiperElement, options)
        this.swiperElement.initialize()
      }
    },
  }
}
</script>

<style lang="scss">
  .gallery--wrap {
    .swiper--wrap {
      width: 80vw;
      max-height: 80vh;

      swiper-container {
        height: 100%;
        width: 100%;

        swiper-slide {
          height: auto;
          background-color: #fff;
          position: relative;

          img {
            display: block;
            max-height: 80vh;
          }

          .slide-caption {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
</style>
